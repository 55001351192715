import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";

import { Id } from "./Id";

export class MyForm extends React.Component {
  render() {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            User id
          </Typography>
          <Typography variant="body2" component="p">
            Please insert the user name to retrive details
          </Typography>
          <br />
          <Id />
        </CardContent>
      </Card>
    );
  }
}

import React from "react";
import { TextField, Button, Typography } from "@material-ui/core";

export class Id extends React.Component {
  state = {
    userName: "",
    id: "",
    name: "",
    infoText: "",
  };

  userIdFetch = async (user) => {
    const response = await fetch(
      `https://api.nautica.dev/.netlify/functions/id?${user}`,
      { mode: "cors" }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        return data;
      });

    return response;
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.userIdFetch(this.state.userName).then((data) => {
      if ("data" in data) {
        this.setState({
          id: data.data.id,
          name: data.data.name,
          infoText: `User Id: ${data.data.id} <br>Full Name: ${data.data.name}`,
        });
      } else {
        this.setState({
          infoText: `Username ${this.state.userName} not found`,
        });
      }
    });

    // Do something with the form data
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          label="User Name"
          variant="outlined"
          type="text"
          name="userName"
          value={this.state.userName}
          onChange={this.handleChange}
        />
        <br />
        <br />

        <Button variant="contained" color="primary" type="submit">
          Submit
        </Button>
        <br />
        <br />
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: this.state.infoText }}
        />
      </form>
    );
  }
}

import "./App.css";
import React from "react";
import { CssBaseline } from "@material-ui/core";

import { LoginForm } from "./components/LoginForm";
import { Header } from "./components/Header";

function App() {
  return (
    <div className="App">
      <CssBaseline />
      <Header />
      <LoginForm />
    </div>
  );
}

export default App;

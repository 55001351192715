import React, { useState } from "react";

import fetch from "node-fetch";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { MyForm } from "./id/MyForm";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

export function LoginForm() {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = async () => {
      const reqData = await fetch(
        "https://api.nautica.dev/.netlify/functions/api/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Origin",
          },
          body: JSON.stringify({
            username,
            password,
          }),
          mode: "cors",
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          return data.token;
        });
      return reqData;
    };
    const token = await response();

    if (token === undefined) {
      setError("Invalid username or password");
    } else {
      localStorage.setItem("token", token);
      setIsLoggedIn(true);
    }
  };

  if (isLoggedIn) {
    return (
      <div>
        {" "}
        <p>Welcome!</p> <MyForm />{" "}
      </div>
    );
  } else if (error) {
    return (
      <div className={classes.root}>
        <h1>Login Failed</h1>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      {error && <p>{error}</p>}
      <TextField
        id="username"
        label="Username"
        value={username}
        onChange={(event) => setUsername(event.target.value)}
      />
      <TextField
        id="password"
        label="Password"
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />
      <Button variant="contained" color="primary" type="submit">
        Log In
      </Button>
    </form>
  );
}
